.App {
  text-align: center;
}

.bold {
    font-weight: bold;
}

.marginBottom {
    margin-bottom: 2em;
}

.button {   
    border: solid 1px;
    color: white;
    border-radius: 5px;
    padding: 1em;
    font-weight: bold;
}

.buttonPrimary {
    background-color: var(--primary);
}

.button:hover {
    /* border-color: highlight; */
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5) inset;
    /* background-color: highlight;
    color: highlighttext; */
}

.buttonSecondary {
    background-color: var(--accent);
}

.input {
    padding: .5em;
    margin: .5em;
}

.input:focus {
    border-color: #2a2d48;
}

.inputName {
    width: 30em;
}

.inputNumber {
    width: 10em;
}

@media screen and (max-width: 480px) {
    body {
        font-size: 16px; /* set the base font size for the body */
      }
      
      /* set the font size for all text elements within the body */
      body * {
        font-size: 0.8rem;
      }
      .header {
        background-color: var(--primary);
        color: var(--white);
        margin: 0;
        padding: 1em;
        font-size: 1.3em;
      }
      .inputName {
        width: 22.5em;
    }
    .button {
        padding: .5em .8em;
    }
}