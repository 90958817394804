
.modal { /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
/* Modal Content */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 60%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}
/* Add Animation */
@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}
@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}
/* The Close Button */
.close {
    color: #000;
    float: right;
    font-size: 28px;
    font-weight: bold;
    border: 0;
    background-color: var(--primary);
    color: var(--white);
}
.close:hover,
.close:focus {
    color: var(--accent);
    text-decoration: none;
    cursor: pointer;
}
.modal-header {
    padding: 2px 16px;
    background-color: #040b45;
    color: white;
}
.modal-body {
    padding: 20px 16px;
    text-align: left;
}
.modal-footer {
    padding: 2px 16px;
    background-color: #451b04;
    color: white;
}
.display-block {
  display: block;
}
.display-none {
  display: none;
}

@media screen and (max-width: 480px) {
    
      .modal {
        padding-top: 45px;
      }

      .modal-content {
        width: 75%;
      }

      .modal-body {
        padding: 0px 16px;
      }
    }