.addButton {
    padding: .7em;
}

.editTask {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.saveNumber {
    width: 2em;
}

.errorMessage {
    margin: 0;
    color: var(--accent);
}

@media screen and (max-width: 480px) {
    .addButton {
        padding: .7em 11em;
    }
    .saveNumber {
        width: 2em;
    }
    }


